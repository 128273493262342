<template>
  <div>
    <div>
      <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="labelCol" :wrapper-col="wrapperCol" >
        <a-form-model-item label="标题" prop="title">
          <a-input v-model="form.title" :maxLength="60" placeholder="请输入标题">
            <div slot="addonAfter">{{ form.title.length }}/60</div>
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="介绍" prop="introduction">
          <a-input v-model="form.introduction" :maxLength="60" placeholder="请输入介绍">
            <div slot="addonAfter">{{ form.introduction.length }}/60</div>
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="背景图片" prop="background">
          <UpdataImg
            @avatarfns="data => form.background = data[0]" 
            :imageUrlNum="1" 
            :imgUrl="form.background ? [form.background] : []"
          />
        </a-form-model-item>
        <a-form-model-item label="分享海报" prop="image">
          <CropperUpload
						:imageUrl="form.image"
						:options="headerOptions"
						@avatarfn="getImgUrl"
						avatarTextTop="JPG.PNG.JPEG格式"
						avatarTextBottom=""
          />
        </a-form-model-item>
        <a-form-model-item ref="cover" label="封面图" prop="cover">
          <CropperUpload
						:imageUrl="form.cover"
            :options="headerOptions"
						@avatarfn="getImgCoverUrl"
						avatarTextTop="JPG.PNG.JPEG格式 图片小于200K"
            avatarTextBottom=""
          />
        </a-form-model-item>
        <a-form-model-item ref="appendix" label="音频上传" prop="appendix">
          <QiniuVideoAudio
            :oldFileName="form.appendixName"
            :type="1"
            @videoFn="getAudioInfo"
          />
          <div style="font-weight: 600">格式支持mp3，为保证音频加载与播放的流畅性，建议上传大小不超过300M。</div>
        </a-form-model-item>
        <a-form-model-item label="日期" prop="readDate" class="por" required>
          <a-date-picker v-model="form.readDate" placeholder="选择日期" :disabled-date="disabledDate" style="width: 250px;"/>
        </a-form-model-item>
      </a-form-model>
    </div>

    <FooterToolBar :collapsed="sideCollapsed">
			<a-button class="margin_right60" @click="() => $router.go(-1)">返回</a-button>
			<a-button type="primary" @click="handleSave">保存</a-button>
    </FooterToolBar>
  </div>
</template>

<script>
import FooterToolBar from '@/components/FooterToolbar'
import { baseMixin } from '@/store/app-mixin'
import CropperUpload from '@/components/file/CropperUpload'
import QiniuVideoAudio from '@/components/file/QiniuVideoAudio.vue'
import { disabledDate } from '@/utils/tools'
import moment from "moment"
import UpdataImg from "@/components/file/updataImg";
import { addDailyReadingAPI, editDailyReadingAPI, getFreeAudioDetailAPI } from '@/request/api/videoManage'

export default {
    mixins: [baseMixin],
    components: {
      CropperUpload,
      FooterToolBar,
      QiniuVideoAudio,
      UpdataImg
    },
    data() {
      return {
        isAdd: true,
        disabledDate,
        labelCol: { span: 2 },
        wrapperCol: { span: 10 },
        arrClassify:[],
        form: {
          id: +this.$route.params.id !== 0 ? this.$route.params.id : '',
          title:'',
          introduction: '',
          background: '',
          image: '',
          cover: '',
          appendix:'',
					readDate: '',
					appendixTime: '', // 音频时长
					appendixName: '' // 音频名称
        },
        rules: {
          title: [
            { required: true, message: "请输入音频标题", trigger: "blur" },
            { max: 60, message: "最多输入60个字符", trigger: "blur" },
          ],
          introduction: [
            { required: true, message: "请输入音频简介", trigger: "blur" },
            { max: 60, message: "最多输入60个字符", trigger: "blur" },
          ],
          background: [{ required: true, message: "请上传背景图片", trigger: ['change', 'blur']}],
          image: [{ required: true, message: "请上传海报封面", trigger: ['change', 'blur']}],
          cover: [{ required: true, message: "请上传封面图", trigger: ['change', 'blur']}],
          appendix: [{ required: true, message: "请上传音频课程", trigger: ['change', 'blur']}],
          readDate: [{ required: true, message: "请选择日期", trigger: ['change', 'blur']}],
        },
        headerOptions: {
          maxImgSize:"20000",
          autoCrop: false, //是否默认生成截图框
          autoCropWidth: 400, //默认生成截图框宽度
          autoCropHeight: 400, //默认生成截图框高度
          fixedBox: true, //是否固定截图框大小 不允许改变
          previewsCircle: false, //预览图是否是原圆形
          title: "修改图片",
        }
      };
    },
    created() {
      if(+this.$route.params.id !== 0){
        this.isAdd = false
        // 获取详情
				this.getDetail()
      }
    },
    methods: {
      getImgCoverUrl(res) {
        const { url } = res
        this.form.cover = url.split('?')[0]
      },
      getImgUrl(res) {
        const { url } = res
        this.form.image = url.split('?')[0]
      },
			async	getDetail() {
				const params = {
					id: this.form.id
				}
				const { code, data } = await getFreeAudioDetailAPI(params)
				if (code !== 200) return
				this.form = Object.assign(this.form, data)
			},
			getAudioInfo(obj) {
				this.form.appendix = obj.fileUrl
				this.form.appendixTime = Math.round(Number(obj.videoTime))
				this.form.appendixName = obj.oldFileName
			},
			// 保存的按钮
			handleSave() {
				this.$refs.ruleForm.validate((valid) => {
					if (!valid) return
					this.form.readDate = moment(this.form.readDate).format('yyyy-MM-DD')
					this.isAdd ? this.addDailyReading() : this.editDailyReading()
				})
			},
		 	async	addDailyReading() {
				const params = {
					...this.form
				}
				const { code } = await addDailyReadingAPI(params)
				if (code !== 200) return
				this.$message.success('添加成功')
        this.$router.push('/videoManage/freeAudio')
			},
		 	async	editDailyReading() {
				const params = {
					...this.form
				}
				const { code } = await editDailyReadingAPI(params)
				if (code !== 200) return
				this.$message.success('修改成功')
        this.$router.push('/videoManage/freeAudio')
			}
			
    },
};
</script>